.bg-white {
  background-color: white;
}

.padding-10 {
  padding: 10px;
}
.page-container {
  margin: 0 7%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.full-width {
  width: 100%!important;
}

.margin-20 {
  margin: 20px 20px 20px 0;
}

@media screen and (min-width: 800px) {
  .flex-col {
    flex: 1
  }
}

.chart-04 {
  height: 700px!important;
}

.chart-05 {
  height: 412px!important;
}
.points {
    padding: 40px 0px;
  
    .points-title {
      font: normal normal bold 27px Flame Sans;
      letter-spacing: 0px;
      color: #222222;
      text-transform: capitalize;
    }
    .points-container {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
  margin-bottom: 20px;
.points-card {
  padding: 10px;
  border-radius: 6px;
  display:flex;
  flex-direction:column ;
    justify-content: space-between;
    background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 6px;
  .points-card-div{
    display: flex;
    
    .alert-svg{
      width:30px;
      align-self: flex-start;
      margin-top:3%;
      margin-right:10px;
    }
  .points-card-container {
   
    .points-card-detail {
      text-align: left;
      font: normal normal normal 25px Flame Sans;
      letter-spacing: 0px;
    }
    .points-card-period {
      font: normal normal normal 20px Flame Sans;
      letter-spacing: 0px;
      color: #7d7f7d;
    }
    .points-card-category {
      font: normal normal bold 20px Flame Sans;
      letter-spacing: 0px;
      color: #222222;
    }
    .table-cell {
      font: normal normal normal 20px Flame Sans;
      letter-spacing: 0px;
      color: #484848;
      border:none !important;
      li::marker {
        color:  #502314;
      }
    }
    .table-cell-points {
      font: normal normal normal 15px Flame Sans;
      letter-spacing: 0px;
      color: #484848;
      text-align: right;
      border:none !important;
    }
  }
}
  .points-card-categories {
    background: url('../../../assets/View\ all\ Standards.png');
    background-repeat: no-repeat;
    background-position:center;
    border-top: 1px solid #ebebeb;
    height:40px;
   
  }

}
  }
}
