div.extended-text-container{
  display: grid;
  grid-template-columns: 80px repeat(1, 1fr) 80px;
  grid-template-rows: repeat(1, 900px);

  svg.svg.background {

    background-color: #FFAD5E;
  }
  svg.svg.PLK{
    background: url('../../assets/Mask Group 324.svg');
    background-color: #FFAD5E;
  }

}

image.even {
  x: 0;
  y:0;
  transition: x 1s ease-in-out;
}

text {

  &.text.header-greeting {
    font-family: 'Chicken SansBold';
    font-size: 42px;
    margin-left: 5.5%;
    transition: opacity 1s ease-in-out;
    opacity: 1;

    &.not-visible {
      transition: opacity 1s ease-in-out;
      opacity: 0;
    }

  }

}

div.container {
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 80px repeat(6, 1fr) 80px;
  //grid-template-rows: repeat(2, 451px);
  //grid-column-gap: 40px;

  h1.header-greeting {
    font-family: 'Chicken SansBold';
    font-size: 42px;
    margin-left: 5.5%;
  }
}

div.splash {
  background: url('../../assets/Mask Group 324.svg');
  background-color: #FFAD5E;
  grid-column: 1 / 13;
  grid-row: 1 / 2;
}

div.dashboard-layout {
  grid-column: 1 / 9;
  grid-row: 1 / 2;
  display: grid;
  grid-template-columns: 114px 400px repeat(5,1fr) 114px;
  grid-template-rows: 100px 451px;
}

div.white-space {
  grid-column: 1 / 13;
  grid-row: 2 / 3;
}

div.scores {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 451px);
  column-gap: 30px;
}

div.grade {
  grid-row: 1 / 3;

  svg {
    position: absolute;
  }

}

.circle {
  -webkit-filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .3));
  filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .3));

  &.circle-A{
    fill: #00B275;
  }

  &.circle-B {
    fill: #00B2A9;
  }

  &.circle-C {
    fill: black;
  }

  &.circle-D {
    fill: #FF7D00;
  }

  &.circle-F {
    fill: #D90F06;
  }

  &.pending {
    stroke: #00B2A9;
    stroke-width: 6;
    -webkit-filter: none;
    filter: none;
  }

  #top {
    -webkit-filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .3));
    filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .3));
  }
}


text.text-grade {
  fill: #FFFFFF;
  font-family: 'Chicken SansBold';
  font-size: 100px;
}

text.text-grade-pending {
  fill: #7D7F7D;
  font-family: 'Chicken SansBold';
  font-size: 2em;
}

g.g-scores {
  cursor: pointer;
}

text.text-period {
  font-family: 'Chicken Sans';
  font-size: 27px;

  &.text-active {
    fill: #484848;
  }

  &.text-pending {
    fill: #7D7F7D;
  }

}
