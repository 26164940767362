    // .modal-Score{
    //     border-color: #48484840;
    // }  

    .CancelButton {
        // top: 677px;
        width: 66px;
        height: 25px;
        border-style: none;
        font-size: 21px;
        font-family: Flame Sans;
        color: #484848;
        text-transform: capitalize;
        background-color: #FFFF;
        left: 540px;
        position: fixed;

    }

    .okbutton {
        background: #693F34;
        border-color: #693F34;
        border-radius: 5px;
        opacity: 1;
        top: 669px;
        left: 300px;
        width: 132px;
        height: 40px;
        border-radius: 5px 5px 5px 5px;
        margin-left: 78%;
        color: #FFFFFF;
        font-family: Flame Sans;
        // margin-right: 10%;
        // position: fixed;
    }
    
    .p1{
        font-family: FlameSans;
        font-size: 31px;
        padding: 0px 135px 8px 80px;
        // padding: 0px 143px 8px 89px;
    }
    .p2 {
        font-family: Flame Sans;
        font-size: 25px;
        padding:0px 80px 10px 89px ;
        
    }


