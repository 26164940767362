
    .MuiAppBar-root {
      display: flex !important;
      width: 100% !important;
     height: 75px;
      align-items: center;
      flex-flow: row nowrap;
      justify-content: space-between;
      background-color:#502314 !important;

      .toolbar {
        flex-wrap: wrap;
        display: flex !important;
        flex-grow: 1;
        color: #fff;
        width: 100%
      };
      .scoreCard{
      
        margin-right: 10px;
        text-transform: none;
        white-Space: nowrap;
        font-Size:39px;
        color: #FFFFFF;
        font-family:"FlameSans-Bold";
        
      };
      .brandLogo{
        background-image: url("../../assets/burger-king-new-2021-seeklogo.com-3.svg");
        background-repeat: no-repeat;
        height:50px;
        margin-top:10px;
      }
    }

    .navList {
      display: flex !important;
      flex: 1;
      padding: 0px;
      align-items: center;
    };
    .MuiList-root {
      display: flex !important;
      flex: 1;
      padding: 0px;
      align-items: center;
      height:75px;
    }
    .rightHeader {
      flex: .2 ;
      margin-left: 3%
    };
    .MuiBox-root-19 {
      flex: 0.8;
      }

  
    .leftheader {
      flex: .8;
      text-align: end;
     
    };
    .brandLogoOnScroll {
      
      width: 45px;
      height:45px
    };
    .brandLogoOnScrollPLK {
      width: "92.31px";
      height:"45px"
    };

    .iconButton{
  padding:"2px"
    }
