div.breadcrumb-trail-head {
  grid-column: 2 / 8;
  grid-row: 1 / 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 35% 25% 35%;
}

div.dashboard-first-line {
  grid-column: 2 / 8;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: 400px repeat(5, 1fr);
  grid-template-rows: 50px 376px;
}


div.splash {

  h1.header-greeting {
    grid-column: 1 / 13;
    grid-row: 1 / 2;
    font-family: 'FlameSans';
    color: #FFFFFF;
    font-size: 39px;
  }

}
