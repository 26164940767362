svg {

  .outerSlices.segment.\31 {
    fill: url('#gradient-outer-ring-rating-fill');
  }
  .outerSlices.segment.\32 {
    fill: url('#gradient-outer-ring-rating-fill');
  }
  .outerSlices.segment.\33 {
    fill: #F2F2F2;
  }
  .innerSlices.segment.F {
    fill: url('#gradient-F');
  }
  .innerSlices.segment.D {
    fill: url('#gradient-D');
  }
  .innerSlices.segment.B {
    fill: url('#gradient-B');
  }
  .innerSlices.segment.A {
    fill: url('#gradient-A');
  }
  stop.initialStop-F {
    stop-color: #C83A21;
  }
  stop.endingStop-F {
    stop-color: #D66234;
  }
  stop.initialStop-D {
    stop-color: #D86736;
  }
  stop.endingStop-D {
    stop-color: #BA925C;
  }
  stop.initialStop-B {
    stop-color: #6F9E78;
  }
  stop.endingStop-B {
    stop-color: #00B38D;
  }
  stop.initialStop-A {
    stop-color: #00B38B;
  }
  stop.endingStop-A {
    stop-color: #00B375;
  }
}

text.donut-label {

  font-family: "Flame Sans";
  font-size: 17px;

  &.F {
    fill: #C6371E;
  }
  &.D {
    fill: #C6371E;
  }
  &.B {
    fill: #00B2A9;
  }

  &.A {
    fill: #00B275;
  }
}

text.big-gauge-grade {
  fill: #222222;
  font-size: 88px;
  font-family: "FlameSans";
}






