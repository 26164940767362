.emp-table {
    background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 10px;
// padding: 20px;

.MuiPagination-root	{
    margin-left: 45%;
    margin-top: 10px;
  
letter-spacing: 0px;
.MuiPaginationItem-root{
font: normal normal normal 27px Flame Sans;
}
.Mui-selected{
color: #502314 ;
background-color: transparent;

}
}

  .emp-title {
    text-align: left;
    font: normal normal bold 31px/46px Flame-regular;
    letter-spacing: 0px;
    color: #222222;
  }
  .table {
    padding:10px;
    .MuiTableHead-root{
      border-bottom: 1px solid #707070;
    }
    // .MuiTableRow-root{
      
    // }
    .emp-table-title {
      text-align: center;
      font: normal normal normal 25px/30px Flame Sans;
      letter-spacing: 0px;
      color: #222222;

      opacity: 1;
    }
    .emp-cell {
      text-align: center;
      font: normal normal normal 20px/24px Flame Sans;
      letter-spacing: 0px;
      color: #222222;
    }
    .left {
      text-align: left;
      .sort-icon{
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}
