.tooltip {
    position:absolute;
    padding:5px;
    left:500px;
    text-align: center;
    border-radius: 6px;
    margin-left: -60px;		
    background: white;	
    border: 0px;		
    border-radius: 8px;			
    pointer-events: none;		
    font-family: Flame Sans;
    
    }
    
    
    .tooltip ::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: lightgrey transparent transparent transparent;
    }

    .tooltip-line {
      top: 404px;
      left: 1696px;
      width: 78px;
      height: 31px;
      // transform: matrix(0, -1, 1, 0, 0, 0);
      background: var(--white) 0% 0% no-repeat padding-box;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 12px #0000001A;
      opacity: 1;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: lightgrey transparent transparent transparent;
      font-family: Flame Sans;
    }

    .tooltip-line ::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: lightgrey transparent transparent transparent;
    }

    .xaxis text {
      stroke:#484848;
      font-family: Flame Sans;
      font-size: 20px;
    }

    .yaxis text {
      stroke:#484848;
      font-family: Flame Sans;
      font-size: 15px;
    }