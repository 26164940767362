.rootHow {
  padding-left: 50px;

  .s-success-title {
    text-align: left;
    font: normal normal bold 39px Flame Sans;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
  }
  .static-containers-colored{
    margin-left: -50px !important;
    margin-top: 25px ;
  }
  .static-containers-white{
  
    margin-top: 20px ;
    .static-image-white{
      padding-left:-50px
    }
  }
  
  .static-image{
    width: 98vw;
  }
  .static-image-thresholds{
    width: 50vw;
    margin-left: 20% !important;
  }
  .static-image-white{
    width: 90vw;
  }
  .s-success-contentTitle {
    text-align: left;
    font: normal normal normal 31px Flame Sans;
  }
  .s-success-content {
    text-align: left;
    font: normal normal normal 25px Flame Sans;
    letter-spacing: 0px;
    color: #35363a;
    opacity: 1;
  }
  .s-small-content{
    text-align: left;
font: normal normal normal 25px Flame Sans;
letter-spacing: 0px;
color: #35363A;
opacity: 1;
  }
  .s-small-title{
    text-align: left;
font: normal normal bold 30px  Flame Sans;
letter-spacing: 0px;
color: #222222;
opacity: 1;
  }
  .success-divs {
    background: #fff4cc 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 99vw;
    margin-left: -50px !important;
    padding-left: 50px !important;
  }
  


}
