.comments {
  padding:40px 0;
    .MuiPagination-root	{
        margin-left: 45%;
        margin-top: 15px;
        position: relative;
        top:30px;
      
letter-spacing: 0px;
.MuiPaginationItem-root{
    font: normal normal normal 27px Flame Sans;
}
.Mui-selected{
    color: #502314;
    background-color: transparent;
    
}
    }

  .comment-header {
    display: flex;
    justify-content: space-between;
    .header-title {
      text-align: left;
      font: normal normal normal 31px/37px Flame Sans;
      letter-spacing: 0px;
      color: #222222;
      text-transform: capitalize;
    }
    .header-legend {
      display: flex;
      .header-legend-item {
        text-align: left;
        font: normal normal normal 20px/24px Flame Sans;
        letter-spacing: 0px;
        color: #222222;
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
      .escalation {
        background: #d90f06 0% 0% no-repeat padding-box;
      }
      .problem {
        background: #f27900 0% 0% no-repeat padding-box;
      }
      .suggestion {
        background: #f3ad3d 0% 0% no-repeat padding-box;
      }
      .legend-div {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
  .comments-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 2%;
    grid-row-gap: 3%;
    .level1,.level2{
        border-left:7px solid #D90F06;
    }
    .level3{
        border-left:7px solid #F27900 ;
    }
    .level4,.level5{
        border-left:7px solid #F3AD3D;
    }
    .comment-card {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      opacity: 1;
      padding: 10px;
      flex: 4;
        
      .comment-card-title {
        display: flex;
        align-items: center;
        .comment-date {
          text-align: left;
          font: normal normal normal 20px/30px Flame Sans;
          letter-spacing: 0px;
          color: #222222;
          margin:0px 3px;
          border-right: 2px solid #CDCDCD;
         padding-right: 10px;
        }
        .comment-title {
          text-align: left;
          font: normal normal normal 20px/30px Flame Sans;
          margin: 0px 3px;
          color: #222222;
        }
      }
      .comment-level{
        text-align: left;
        font: normal normal normal 20px/30px Flame Sans;
        letter-spacing: 0px;
        color: #222222;
      }
      .comment-description{
        text-align: left;
        font: normal normal normal 16px/26px Flame Sans;
        letter-spacing: 0px;
        color: #484848;
      }
    }
  }
    }

    @media only screen  and (max-width:600px){

      .root{
        .MuiPagination-root	{
        margin-left: 25%;  
        position: relative;
        top:350px;
        }
        .comment-header{
          flex-direction: column;
          .header-legend{
            flex-direction: column;
          }
        }
        .comments-container{
          grid-template-columns: repeat(1,300px);
          justify-content: center;
          .comment-card{
            .comment-card-title{
              flex-direction: column;
              align-items: flex-start;
              .comment-date{
                font-size: 15px;
                border-right: none;
              }
              .comment-title{
                font-size: 15px;
              }
            }
            .comment-level{
              font-size: 15px;
            }
              .comment-description{
                font-size: 15px;
              }
            
          }
        }
      }
    }