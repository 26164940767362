
/*
.chart rect {
  fill: steelblue;
}
*/
.chart .legend {
    fill: black;
    font: 14px sans-serif;
    text-anchor: start;
    font-size: 12px;
  }
  
  .label{
    fill: white;
    font: 10px sans-serif;
    text-anchor: center;
  }
  
  .chart .label {
    fill: black;
    font: 14px sans-serif;
    text-anchor: end;
  }
  
  /* .bar:hover {
    fill: brown;
  } */
  
  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }
  