.alert-container { 
    background-color: #FFF2F2 0% 0% no-repeat padding-box;  
    border: 1px solid #C6371E;
    top: 784px;
    left: 138px;
    width: 494px;
    height: 96px;

    .text-container {
        top: 897px;
        left: 183px;
        width: 447px;
        height: 52px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-flame-sans);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(---222222);
        text-align: left;
        font: normal normal normal 20px/24px Flame Sans;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
    }

}

.alert-grid {
    display: grid;
    @media only screen and (min-width: 550px) {
        grid-template-columns: 24px 1fr 24px;
        grid-template-rows: 24px 1fr 24px;
    }

    @media only screen and (max-width: 551px) {
        grid-template-columns: 12px 1fr 12px;
        grid-template-rows: 12px 1fr 12px;
    }


    .alert-inner-layout {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }


    &.alert-container-danger {
        top: 873px;
        left: 125px;

        @media only screen and (min-width: 550px) {
            margin: 24px 24px 24px 24px;

            &.arl-dashboard {
                margin: 0px 24px 24px 24px;
            }
        }

        @media only screen and (max-width: 551px) {
            margin: 20px 20px 20px 20px;

            &.arl-dashboard {
                margin: 0px 20px 20px 20px;
            }
        }

        background: var(---fff2f2) 0% 0% no-repeat padding-box;
        border: 1px solid var(--fiery-red);
        background: #FFF2F2 0% 0% no-repeat padding-box;
        border: 1px solid #C6371E;
        border-radius: 5px;
        opacity: 1;

        .svg-container {
            // top: 883px;
            left: 1200px;
            // width: 34px;
            height: 204px;
            // background: transparent url('img/g14.png') 0% 0% no-repeat padding-box;
            opacity: 1;
        }

        .alertMessage{
            // font: normal normal normal 1rem Chicken Sans !important;
            // display: flex;
            align-items: center;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-flame-sans);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(---222222);
            text-align: left;
            font: normal normal normal 20px/24px Flame Sans;
            letter-spacing: 0px;
            color: #222222;
            opacity: 1;
        }

    }

    &.alert-container-success {
        top: 859px;
        left: 1275px;
        @media only screen and (min-width: 550px) {
            margin: 24px 24px 24px 24px;

            &.arl-dashboard {
                margin: 0px 24px 24px 24px;
            }
        }

        @media only screen and (max-width: 551px) {
            margin: 20px 20px 20px 20px;

            &.arl-dashboard {
                margin: 0px 20px 20px 20px;
            }
        }
        background: var(---dfffe3) 0% 0% no-repeat padding-box;
        border: 1px solid var(--crunchy-green);
        background: #DFFFE3 0% 0% no-repeat padding-box;
        border: 1px solid #408441;
        border-radius: 5px;
        opacity: 1;

        .svg-container {
            // top: 883px;
            left: 20%;
            // width: 34px;
            height: 34px;
            // background: transparent url('img/g14.png') 0% 0% no-repeat padding-box;
            opacity: 1;
        }
        .alertMessage{
            // font: normal normal normal 1rem Chicken Sans !important;
            // display: flex;
            align-items: center;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-flame-sans);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(---222222);
            text-align: left;
            font: normal normal normal 20px/24px Flame Sans;
            letter-spacing: 0px;
            color: #222222;
            opacity: 1;
        }
    }

    &.alert-container-warning {
        top: 1368px;
        left: 124px;
        @media only screen and (min-width: 550px) {
            margin: 24px 24px 24px 24px;

            &.arl-dashboard {
                margin: 0px 24px 24px 24px;
            }
        }

        @media only screen and (max-width: 551px) {
            margin: 20px 20px 20px 20px;

            &.arl-dashboard {
                margin: 0px 20px 20px 20px;
            }
        }
        border: 1px solid var(--neutral-toast-ouline);
        background: #FFF0E3 0% 0% no-repeat padding-box;
        border: 1px solid #FFBE81;
        border-radius: 5px;
        opacity: 1;

        .alertType{
            font: normal normal bold 1rem Chicken SansBold;
            padding-left: 5px;
            padding-right: 5px;
        }
        .alertMessage{
            // font: normal normal normal 1rem Chicken Sans !important;
            // display: flex;
            align-items: center;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-flame-sans);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(---222222);
            text-align: left;
            font: normal normal normal 20px/24px Flame Sans;
            letter-spacing: 0px;
            color: #222222;
            opacity: 1;
        }
    }
    
    &.alert-container-error {
        top: 1368px;
        // left: 124px;
        width: 600px;
        float: right;
        margin: 20px 20px 20px 20px;
  
        opacity: 1;
        .alertType{
            font: normal normal bold 1rem Chicken SansBold;
        }

        p {
            width: 210px;
            word-break: break-all;
		}

        .alertMessage{
            align-items: center;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-flame-sans);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(---222222);
            text-align: center;
            font: normal normal normal 20px/24px Flame Sans;
            letter-spacing: 0px;
            color: #222222;
            opacity: 1;
            
        }
    }

}
