.tooltip-card{
  margin-bottom: 10px;
  .tooltipHead{
    font: normal normal normal 14px/17px Flame-regular;
letter-spacing: 0px;
color: #222222;
margin-bottom: 5px;
  }
  .tooltipOptions{
    font: normal normal normal 14px/17px Flame Sans;
letter-spacing: 0px;
color: #484848;

  }
  .tool-learnmore{
    text-align: left;
text-decoration: underline;
font: normal normal normal 14px/17px Flame Sans;
letter-spacing: 0px;
color: #484848;
padding-top:20px;
margin-bottom: 10px;
  }
}
 
.cards-layout {
  grid-column: 2 / 7;
  grid-row: 1 / 3;
  display: grid;
  grid-template-rows: 50px 376px;
  grid-column-gap: 15px;

  div.cards-title {

    grid-column: 1 / 2;
    grid-row: 1 / 2;
    .infoIcon{
      width:20px;
      height:20px;
      margin-left:10px;
      
    }
   

    text.h2-card-header {
      font-size: 31px;
      font-family: 'FlameSans';

      &.Guest {
        color: #00857E;
      }

      &.Team {
        color: #911987;
      }

      &.Standards {
        color: #C6371E;
      }

    }
  }
}
