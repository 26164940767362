.restStyle  {
    padding-left:6%;
    font: normal normal bold 42px/52px Flame Sans;
    color: #222222;
}
 .breadCrumb{
    font: normal normal normal 21px/24px Flame Sans;
    color: #502314;
    padding-left:6%;
    padding-top:2%;
}
div.all-restaurant-container-breadcrumb {
  display: grid;
  grid-template-columns: 5.9% repeat(6,1fr) 5.9%;
}
div.breadcrumb-trail-head {
    grid-column: 2 / 8;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 35% 25% 35%;
}