.points {
  padding: 40px 0px;
  .points-title {
    font: normal normal bold 27px Chicken Sans;
    letter-spacing: 0px;
    color: #222222;
    text-transform: capitalize;
  }
  .points-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-bottom: 20px;
    .points-card {
      padding: 10px;
      border-radius: 6px;
      display:flex;
      flex-direction:column ;
        justify-content: space-between;
        background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 6px;
      .points-card-div{
        display: flex;
        
        .alert-svg{
          width:30px;
          align-self: flex-start;
          margin-top:3%;
          margin-right:10px;
        }
      .points-card-container {
       
        .points-card-detail {
          text-align: left;
          font: normal normal normal 25px Chicken Sans;
          letter-spacing: 0px;
        }
        .points-card-period {
          font: normal normal normal 20px Chicken Sans;
          letter-spacing: 0px;
          color: #7d7f7d;
        }
        .points-card-category {
          font: normal normal bold 20px Chicken Sans;
          letter-spacing: 0px;
          color: #222222;
        }
        .table-cell {
          font: normal normal normal 20px Chicken Sans;
          letter-spacing: 0px;
          color: #484848;
          border:none !important;
          li::marker {
            color: #ff7d00;
          }
        }
        .table-cell-points {
          font: normal normal normal 15px Chicken Sans;
          letter-spacing: 0px;
          color: #484848;
          text-align: right;
          border:none !important;
        }
      }
    }
      .points-card-categories {
        // text-align: center;
        border-top: 1px solid #ebebeb;
        // cursor: pointer;
        // padding-top: 10px;
        // color: #ff7d00;
        // font: normal normal bold 20px Chicken Sans;
        background: url('../../assets/View\ all\ categories.png');
        background-repeat: no-repeat;
        background-position:center;
        height:40px;
      }
    
    }
  }
}
