div.odometer-layout {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 550px) {
    grid-template-rows: 70px 210px 166px;
  }
  @media only screen and(max-width: 551px) {
    grid-template-rows: 10% 90% 0%;
  }

  div.odometer-title {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    align-items: baseline;
    p {
      font-family: 'FlameSans';
      font-size: 34px;
      margin: 0;
    }
    .infoIcon{
      margin-left: 5px;
      width: 20px;
    }

  }

  div.odometer-graphic {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: 1fr;

    @media only screen and (min-width: 550px) {
      grid-template-rows: 210px 166px;
    }
    @media only screen and(max-width: 551px) {
      grid-template-rows: 210px 166px;
    }

    div.odometer-star-rating {
      display: grid;
      grid-template-columns: 50px 1fr 50px;

      @media only screen and (min-width: 550px) {
        grid-template-rows: 28px 90px;
      }
      @media only screen and(max-width: 551px) {
        grid-template-rows: 0% 0%;
      }

      .odometer-start-rating-svg-div {
        grid-row: 2 / 3;
        grid-column: 2 /3;

        text.text-average-star-rating {
          color: #484848;
          font-family: "FlameSans";
          font-size:20px;
        }
      }
    }

    div.odometer-svg {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

  }
}
