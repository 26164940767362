div.container {
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: 37px repeat(6, 1fr) 37px;
  //grid-template-rows: repeat(2, 451px);
  //grid-column-gap: 40px;

  h1.header-greeting {
    font-family: 'FlameSans';
    text-transform: capitalize;
    color: #FFFFFF;
    font-size: 39px;
    margin-left: 5.5%;
    margin-bottom: 2%;
  }

  text.text-grade-pending {
    fill: #7D7F7D;
    font-family: 'Flame Sans';
    font-size: 1.75em;

    &.grading-screen {
      fill: #408441;
      font-size: 29px;
      font-family: 'FlameSans-Bold';

      &.Restaurant.Manager {
        fill: #7D7F7D;
        font-family: 'Flame Sans';
      }
    }
  }


}

div.splash {
  background: url('../../assets/Cucumber Girl.svg');
  background-color: #408441;
  background-repeat: no-repeat;
  background-position: right;
  grid-column: 1 / 13;
  grid-row: 1 / 2;

}

div.dashboard-layout {
  grid-column: 1 / 9;
  grid-row: 1 / 2;
  display: grid;
  grid-template-columns: 5.9% 400px repeat(5,1fr) 5.9%;
  grid-template-rows: 150px 501px;
  grid-row-gap: 32px;
}

div.white-space {
  grid-column: 1 / 13;
  grid-row: 2 / 3;
}

div.scores {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 451px);
  column-gap: 30px;
}

div.grade {
  grid-row: 1 / 3;

  svg {
    position: absolute;
  }

}

.circle {
  -webkit-filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .3));
  filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .3));

  &.arl {
    stroke: #408441;
    stroke-width: 5px;
  }

  &.circle-A{
    fill: #418441;
  }

  &.circle-B {
    fill: #00B375;
  }

  &.circle-C {
    fill: black;
  }

  &.circle-D {
    fill: #FF8731;
  }

  &.circle-F {
    fill: #C6371E;
  }

  &.pending, &.Grading.in.progress, &.Grading.in.Progress {
    stroke: #408441;
    stroke-width: 6;
    fill: url(#image);
    -webkit-filter: none;
    filter: none;
  }

  #top {
    -webkit-filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .3));
    filter: drop-shadow( 0px 3px 6px rgba(0, 0, 0, .3));
  }
}


text.text-grade {
  fill: #FFFFFF;
  font-family: 'FlameSans';
  font-size: 100px;
}


g.g-scores {
  cursor: pointer;
}

text.text-period {
  font-family: 'FlameSans';
  font-size: 25px;

  &.text-active {
    fill: #484848;
  }

  &.text-pending {
    font-family: 'Flame Sans';
    fill: #408441;

    &.month {
      font-family: 'FlameSans';
    }
  }

}
