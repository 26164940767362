
div.settings {
  grid-column: 9 / 10;
  grid-row: 1 / 2;
  margin: auto;

  div#sort-div {

    &.settingsHovered {
      position: absolute;
      z-index: 1;
      background-color: #FFFFFF;
      box-shadow: 0px 3px 12px #00000014;
      margin-top: 12px;
      border-radius: 5px 5px 5px 5px;
      width: 282px;
      right: 5.9%;
      flex-direction: column;
      align-items: flex-start;

      p.MuiTypography-root {
        padding: 26px 26px 0px 26px;
        font-family: "Flame Sans";
        font-size: 20px;
        color: #35363A;

      }

      div.MuiFormGroup-root {
        padding: 26px 26px 26px 26px;

        label.MuiFormControlLabel-root {

          span {
            font-family: 'Flame Sans';
            font-size: 20px;
            color: #35363A;
          }
        }
      }

      div.filterButtons {
        button.applyButton, button.cancelButton {
          float: right;
          color: #7D7F7D;

          &.appliedClick {
            color: #E68A4A;
          }

          span {
            font-size: 20px;
            font-family: 'Flame Sans';
            text-transform: capitalize;
          }
        }
      }

    }

    &.filteredHoveredCancel {
      position: absolute;
      box-shadow: 4px 4px 24px -1px rgba(0, 0, 0, 0.3);
      background: #FFF4CC !important;
      margin-top:25px;
      right: 30px;
      padding: 10px;
      width: 200px;
      z-index: 1;
      display: none;
    }
  }


}


