div.tableUtils {

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 52px;
  margin-bottom: 30px;

  .filtered {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px #CDCDCD solid;
    //width: 125px;
    //height: 60px    ;
    //display: inline-grid;
    //align-items: center;
    //justify-content: center;
    //margin-left: 60px;

    &.filter-by-container {
      grid-column: 6 / 7;
      grid-row: 1 / 2;
    }

    &.sort-by-container {
      grid-column: 7 / 8;
      grid-row: 1 / 2;
    }

    p {
      font-family: 'Flame Sans';
      font-size: 16px;
      color: #484848;
      float: left;
      padding: 10px 10px 10px 10px;
    }

    svg {
      float: left;
      margin: 10px 10px 10px 10px;
    }

    div.filterHeadings {

    }

    .filter:hover .filterHovered{
      cursor: pointer;
      display: block;

    }
    .filterIcon{
      width:18px;
      height:20px
    }

    .filteredHoveredNested{

      position: relative;
      box-shadow: 4px 4px 24px -1px rgba(0, 0, 0, 0.3);
      
      margin-top:25px;
    background: #FFFFFF;
      padding: 10px;
      
      z-index: 1;
     
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .filteredHovered:before{

      //content: '' '';
      //position: absolute;
      //bottom: 100%;
      //top: -30px;
      //width: 0;
      //height: 0;
      //border: 20px solid transparent;
      //border-bottom-color: #FFF4CC;
      //margin: auto;
      //left: 0;
      //right: 0;
      /* boxShadow: '4px 4px 24px -1px rgba(0, 0, 0, 0.3)', */
    }

    div.filteredHoveredCancel {
      //z-index: 1;
      //background-color: #FFFFFF;
      //box-shadow: 0px 3px 12px #00000014;
      //margin-top: 12px;

      div.makeStyles-formControl-30 {
        margin: 0;

        button.filterButtons {
          border-bottom: 1px solid #ECECEC;
          border-radius: 0px;
        }
      }
    }

  }

}

.root-table-container {
  background-color: #fafafa !important;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 50px 1080px;
  box-shadow: none !important;
}
.rest-link-div{
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  border-left: 8px solid #E68A4A;
}
.rest-link{
  text-align: center;
  text-decoration: none;
  font: normal normal normal 25px/30px Flame Sans;
letter-spacing: 0px;
color: #FF7D00;
text-transform: capitalize;
}
.rest-link-bk{
  text-align: center;
  text-decoration: none;
  font: normal normal normal 25px/30px Flame Sans;
letter-spacing: 0px;
color: #4A2518;
text-transform: capitalize;
}


.tabular-div-container-root {
  box-shadow: none;
  grid-column: 1 / 9;
  grid-row: 1 / 2;
  display: grid;

  .table-head-container {
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    .table-row-header{
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 100%;

      .header.restaurant, .header.restaurantBK {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
      }
    }
  }

  .table-body-container {
    grid-column: 1 / 3;
    grid-row: 2 / 3;

    .tablerow {
      //grid-column: 2 / 3;
      //grid-row: 3 / 3;

      margin: 20px 20px 20px 20px;
      background: #FFFFFF;
      //border: 3px solid #E68A4A;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 6px;
      display: grid;
      grid-template-columns: repeat(2, 200px) repeat(5, 1fr);
      grid-template-rows: 50% 50%;

      .first-column-restaurant {
        grid-column: 1 / 3;
        grid-row: 1 / 2;

        p.restaurantName {
          color: #222222;
          font-size: 25px;
          font-family: "FlameSans";
        }
      }

      p.currentValue {
        font-size: 31px;
        color:  #222222;
        font-family: "FlameSans";

      }
    }

  }



}

