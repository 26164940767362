div#trend {
  display: grid;

  @media only screen and (min-width: 550px) {
    grid-template-columns: 2% 96% 2%;
  }

  @media only screen and (max-width: 551px) {
    grid-template-columns: 20px 1fr 20px;
  }


  background: #FFFFFF;
  border-radius: 10px;

  div.d3-graph-integrated-text {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 33% 34% 33%;

    div.star-trend-header-first-line {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: 1fr;

      div.star-trend-rating-padding {
        grid-column: 1 / 4;
        grid-row: 1 / 2;

        @media only screen and (min-width: 550px) {
          p {
            font-family: 'FlameSans';
            font-size: 27px;
            color: #222222;
            margin: 15px 0 0 0;
          }
        }
        @media only screen and(max-width: 551px) {
          p {
            font-family: 'FlameSans';
            font-size: 20px;
            color: #222222;
            margin: 15px 0 0 0;
          }
        }

      }

      div.div-star-trend-weekly {
        grid-column: 10 / 11;
        grid-row: 1 / 2;
        font-family: Flame Sans;
        color: #484848;
        top: 232px;
        left: 1648px;
        width: 51px;
        height: 19px;
        
      }


      div.div-star-trend-monthly {
        grid-column: 11 / 12;
        grid-row: 1 / 2;
        font-family: Flame Sans;
        top: 232px;
        left: 1713px;
        width: 53px;
        height: 18px;
        
      }

      div.div-star-trend-weekly-clicked {
        grid-column: 10 / 11;
        grid-row: 1 / 2;
        text-decoration: underline;
        font-family: Flame Sans;
        color: #4A2518;
        text-underline-position: under;
      }
 
      div.div-star-trend-monthly-clicked {
        grid-column: 11 / 12;
        grid-row: 1 / 2;
        text-decoration: underline;
        font-family: Flame Sans;
        color: #4A2518;
        text-underline-position: under;
      }
      


    }

    div.star-rating-header-graph {
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      p {
        font-family: 'FlameSans';
        font-size: 27px;
        color: #222222;
        margin: 0 0 0 0;
      }
    }

    div.star-rating-header-previous {
      grid-column: 1 / 2;
      grid-row: 3 / 4;

      p {
        font-family: 'Flame Sans';
        font-size: 21px;
        color: #7D7F7D;
        margin: 0;
      }
    }

  }

  div.d3-graph-line-chart-container {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }


}
