.Modal {
  width: fit-content;
  display: grid;
  align-content: center;
  margin: auto;

  .Modal-Head {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    
    .Modal-Head-Title {
      font: normal normal normal 27px FlameSans-Bold;
      letter-spacing: 0px;
      color: #222222;
    }
    .close-icon{
      cursor: pointer;
    }
  }

  .Modal-Body {
    background-color: #ffffff;
    padding:40px;
    outline: none;
    .table {
        .MuiTableRow-head{
            border-bottom: 2px solid #cdcdcd
        }
      .MuiTableCell-head {
        text-align: left;
        font: normal normal normal 21px/25px Flame Sans;
        letter-spacing: 0px;
        color: #222222;
        text-transform: capitalize;
       
      }
      .MuiTableCell-body{
        text-align: left;
        font: normal normal normal 17px/21px Flame Sans;
        letter-spacing: 0px;
        color: #222222;
      }
    }
  }
}
