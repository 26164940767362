svg.svg-success{
    path.a{
        clip-path:url("#success-a");
        // fill:#408441;
    }
    path.b{
         fill:#408441;
    }
    path.c{
        fill:#fff;
    }
}