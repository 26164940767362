.legends{
    display: flex;
    .legend-header{
        float: left;
        text-align: left;
        width: 40%;
        font-family: "FlameSans";
        color: #222222;
        letter-spacing: var(--unnamed-character-spacing-0);
        font-size: 31px;
        
    }
    // justify-content: space-between;
    .legend-text{
        margin: 0 17px 0px 17px;
        float: right;
        text-align: left;
        // width: 45%;
        font: normal normal normal 16px/30px "Flame Sans";
        letter-spacing: 0px;
        color: #222222;
        margin-right: 5px;
        display: flex;
        align-items: center;
        .legend-value {
            margin: 0 8px 0 8px;
        }

        .line-circle{
            width: 48px;
            height: 13px;
            background-image: url('../../assets/imgLineCircle.svg');
            // background-image: url('../../../assets/imgLineCircle.svg'); 
        }
        .blue-box{
            width: 20px;
            height: 20px;
            background-image: url('../../assets/Blue-Rectangle.svg'); 
        }

        .green-box{
            width: 20px;
            height: 20px;
            background-image: url('../../assets/Green-Rectangle.svg'); 
        }
        .red-box{
            width: 20px;
            height: 20px;
            background-image: url('../../assets/Red-Rectangle.svg'); 
        }

        .blue-box-s{
            width: 5px;
            height: 16px;
            background-image: url('../../assets/Blue-Rectangle-S.svg'); 
        }

        .green-box-s{
            width: 5px;
            height: 16px;
            background-image: url('../../assets/Green-Rectangle-S.svg'); 
        }
        .red-box-s{
            width: 5px;
            height: 16px;
            background-image: url('../../assets/Red-Rectangle-S.svg'); 
        }
        .black-box-s{
            width: 5px;
            height: 16px;
            background-image: url('../../assets/Black-Rectangle-S.svg'); 
        }


    }

}