.header{
  text-align: left;
letter-spacing: 0px;
color: #222222;
text-transform: uppercase;
opacity: 1;
}
.guestHead{
  color: #00B2A9 !important;
  text-align: center !important;
  padding:0px;
  padding-top:16px;
    padding-right:16px;
}
.guestHeadBK{
  color: #C6371E !important;
  text-align: center !important;
  padding:0px;
  padding-top:16px;
    padding-right:16px;
}
.teamHeadBK{
  color: #408441 !important;
  text-align: center !important;
}
.teamHead{
  color:#911987 !important;
  text-align: center !important;
}
.standardHead{
  color:#D90F06 !important;
  text-align: center !important;
}
.standardHeadBK{
  color:#673E31 !important;
  text-align: center !important;
}
.MuiTableCell-root{border-bottom: none !important;}

.MuiTableContainer-root{
  width: 100%;
  overflow-x: unset !important;
}
.tablerow:hover{
  border: solid 3px #FF7D00 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.restaurantName{
  text-align: left;
font: normal normal normal 24px Chicken Sans ;
letter-spacing: 0px;
color: #222222;
opacity: 1;
}
.csv-link{
  text-decoration: none;
  font: normal normal normal 16px/17px Chicken Sans;
letter-spacing: 0px;
color: #484848;
}
.xls-link{
  font: normal normal normal 16px/17px Chicken Sans;
letter-spacing: 0px;
color: #484848;
}
.xls-link:hover{
  background: #5023141A 
}
.currentValue{
/*font: normal normal bold 24px Chicken SansBold !important;*/
/*color: #222222 !important;*/
}
.previousValue{
  /*font: normal normal normal 14px Chicken Sans !important;*/
/*color: #7D7F7D !important;*/
}
.redIndicatorUp{
  height: 18px !important;
  width: 18px !important;
  padding-left: 1%;
}
.restaurantPlace{
  text-align: left;
  font: normal normal normal 16px "Chicken Sans" !important;
  color: #484848 !important;
  opacity: 1;
}
.greenIndicatorDown{
  transform: matrix(-1, 0, 0, -1, 0, 0);
  filter: invert(35%) sepia(99%) saturate(452%) hue-rotate(85deg) brightness(92%) contrast(84%);
  height: 18px !important;
  width: 18px !important;
}
.redIndicatorDown{
  transform: matrix(-1, 0, 0, -1, 0, 0);
  height: 18px !important;
  width: 18px !important;
}
.greenIndicatorUp{
  filter: invert(35%) sepia(99%) saturate(452%) hue-rotate(85deg) brightness(92%) contrast(84%);
  height: 18px !important;
  width: 18px !important;
}
.starsDiv{
  display: flex;
  width:100%
}
.starsRating{
  font: normal normal normal 12px Chicken Sans !important;
letter-spacing: 0px;
color: #484848 !important;
justify-content: space-between;

padding-left: 5%;
padding-top: 4%;
}

.stats{
  font: normal normal normal 17px Chicken Sans !important;
letter-spacing: 0px;
color: #484848 !important;
}
.filterHovered{

  /*position: relative;*/
  /*box-shadow: 4px 4px 24px -1px rgba(0, 0, 0, 0.3);*/
  /*background: #FFF4CC !important;*/
  /*margin-top:25px;*/
  /*right: 30px;*/
  /*padding: 10px;*/
  /*width: 200px;*/
  /*z-index: 1;*/
  /*display: none;*/

}
.tableUtils{

}
.filteredHovered{

  /*position: relative;*/
  /*box-shadow: 4px 4px 24px -1px rgba(0, 0, 0, 0.3);*/
  /*background-color: #FFFFFF;*/
  /*margin-top:25px;*/
  /*right: 30px;*/
  /*padding: 10px;*/
  /*width: fit-content;*/
  /*z-index: 1;*/
  /*display: block;*/

}
.filteredHoveredCancel{


}
.filter{
  background: #FFF4CC 0% 0% no-repeat padding-box !important;
  width: 116px;
  height: 60px    ;
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 25px;
}
.filter:hover .filterHovered{
  cursor: pointer;
  display: block;

}
.filterIcon{
  width:18px;
  height:20px
}
.filterHovered::before{

  /*content: '' '';*/
  /*position: absolute;*/
  /*bottom: 100%;*/
  /*top: -30px;*/
  /*width: 0;*/
  /*height: 0;*/
  /*border: 20px solid transparent;*/
  /*border-bottom-color: #FFF4CC;*/
/*margin: auto;*/
/*left: 0;*/
/*right: 0;*/
   /* boxShadow: '4px 4px 24px -1px rgba(0, 0, 0, 0.3)', */
}
.filterHeadings{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-left: 15px;
    cursor: pointer;
}

.dropdown{
    min-width: 170px;
    height: 40px;
    align-items: center;
}
.dropdownSort{
  min-width: 170px;
  height: 50px;
  align-items: center;
}

.TableHeadings{
  grid-column: 2 / 8;
  grid-row: 100%;
  justify-content: space-between;
}
.tableUtilHeadings{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
}
.filterButtons{
  font-size: 0.8rem;
  text-transform: capitalize !important;
}
