.bg-white {
  background-color: white;
}

.padding-10 {
  padding: 10px;
}
.page-container {
  margin: 0 7%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.full-width {
  width: 100%!important;
}

.margin-20 {
  margin: 20px 20px 20px 0;
}

@media screen and (min-width: 800px) {
  .flex-col {
    flex: 1
  }
}

.chart-01 {
  height: 640px!important;
}

.chart-02 {
  height: 500px!important;
}

.chart-03 {
  height: 900px!important;
}