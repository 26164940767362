.card-container {
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #0000001A;
  display: grid;
  grid-template-columns: 1fr;


  div.card-title-first-row {

    grid-column: 1 / 2;
    
    p.text-detail-head {
      font-size: 21px;
      font-family: "Chicken SansBold";
      padding: 5% 5% 0 5%;
      color: #222222;
      margin: 0;
    }

    p.text-weight-byline {
      font-size: 14px;
      color: #484848;
      font-family: "Chicken Sans";
      margin: 0;
      padding: 0% 5% 0 5%;

    }

    div.star-layout-first-row {
      display: grid;
      grid-template-columns: 15% 60% 20%;

      p.p-star-rating {
        color: #484848;
        font-family: "Chicken Sans";
        font-size: 17px;
        margin: 0;
        padding: 20% 0 0 30%;
      }

      div.div-star-rating {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        padding: 3% 0 0 1%;
      }

    }

  }

  div.card-second-row {
    grid-column: 1 / 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 65% 35%;

    div#guestsatisfactionacr-first{

      svg {
        rect#background-clip-guestsatisfactionacr-first {
          fill: #00B2A91A;
        }
        rect#circle-clip-guestsatisfactionacr {
          fill: #008079;
        }
      }

    }

    div#windowtimesos-first {

      svg {
        rect#background-clip-windowtimesos-first {
          fill: #00B2A91A;
        }
        rect#circle-clip-windowtimesos {
          fill: #00837C;
        }
      }

    }

    div#avgtrainingrate-first {

      svg {
        rect#background-clip-avgtrainingrate-first {
          fill: #A7479F1A;
        }
        rect#circle-clip-avgtrainingrate {
          fill: #A7479F;
        }
      }
    }

    div#turnoverrate-first {

      svg {
        rect#background-clip-turnoverrate-first {
          fill: #A7479F1A;
        }
        rect#circle-clip-turnoverrate {
          fill: #A7479F;
        }
      }

    }

    div#brandstandards-first {

      svg {
        rect#background-clip-brandstandards-first {
          fill: #D90F061A;
        }
        rect#circle-clip-brandstandards {
          fill: #D90F06;
        }
      }

    }


    div.inner-bar-chart-line {
      grid-column: 1 / 2;

      svg {

        text {
          font-family: "Chicken Sans";

          &.innerText-value {
            font-family: "Chicken SansBold";
            fill: #FFFFFF;
          }

        }

        path.domain {
          stroke: none;
        }

        text.text-bottom {
          color: white;
        }

        text.text-hide {
          opacity: 0;
        }

      }


    }

    div.star-rating-row-series {
      grid-row: 2 / 3;

      text {
        font-family: "Chicken Sans";
        font-size: 14px;
        fill: #484848
      }
    }

  }

  div.card-third-row {
    grid-column: 1 / 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50% 50%;

    div#guestsatisfactionacr-second {

      svg {
        rect#background-clip-guestsatisfactionacr-second {
          fill: #00B2A91A;
        }
        rect#circle-clip-guestsatisfactionacr {
          fill: #00B2A9;
        }
      }

    }

    div#guestsatisfactionacr-third {

      svg {
        rect#background-clip-guestsatisfactionacr-third {
          fill: #00B2A91A;
        }
        rect#circle-clip-guestsatisfactionacr {
          fill: #00C9BF;
        }
      }

    }

    div#windowtimesos-second {

      svg {
        rect#background-clip-windowtimesos-second {
          fill: #00B2A91A;
        }
        rect#circle-clip-windowtimesos {
          fill: #00B2A9;
        }
      }

    }

    div#windowtimesos-third {

      svg {
        rect#background-clip-windowtimesos-third {
          fill: #00B2A91A;
        }
        rect#circle-clip-windowtimesos {
          fill: #00C9BF;
        }
      }

    }

    div#avgtrainingrate-second {

      svg {
        rect#background-clip-avgtrainingrate-second {
          fill: #A7479F1A;
        }
        rect#circle-clip-avgtrainingrate {
          fill: #A7479FB3;
        }
      }

    }

    div#avgtrainingrate-third {

      svg {
        rect#background-clip-avgtrainingrate-third {
          fill: #A7479F1A;
        }
        rect#circle-clip-avgtrainingrate {
          fill: #D98DD2;
        }
      }

    }

    div#turnoverrate-second {

      svg {
        rect#background-clip-turnoverrate-second {
          fill: #A7479F1A;
        }
        rect#circle-clip-turnoverrate {
          fill: #BE77B8;
        }
      }

    }

    div#turnoverrate-third {

      svg {
        rect#background-clip-turnoverrate-third {
          fill: #A7479F1A;
        }
        rect#circle-clip-turnoverrate {
          fill: #D486CD;
        }
      }

    }

    div#brandstandards-second {

      svg {
        rect#background-clip-brandstandards-second {
          fill: #D90E051A;
        }
        rect#circle-clip-brandstandards {
          fill: #E54942;
        }
      }

    }

    div#brandstandards-third {

      svg {
        rect#background-clip-brandstandards-third {
          fill: #D90E041A;
        }
        rect#circle-clip-brandstandards {
          fill: #D90E04;
        }
      }

    }

    svg {
      text {
        font-family: "Chicken Sans";

        &.innerText-value {
          font-family: "Chicken SansBold";
          fill: #FFFFFF;
        }

        &.barTitle {
          font-family: "Chicken SansBold";
          fill: #484848;
        }

      }
    }
  }



}