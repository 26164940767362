div.table-layout-grid {
  grid-column: 1 / 9;
  grid-row: 2 / 3;
}

div.TableHeadings {
  div.table-header-layout {
    display: grid;
    grid-template-columns: 5.9% repeat(6, 1fr) 9% 3% 5.9%;
    grid-template-rows: 100%;

    div.text-layout-table-description {
      grid-column: 2 / 4;
      grid-row: 1 / 2;

      p.restaurantsList {
        text-align: left;
        font-size: 31px;
        font-family: "FlameSans";
        letter-spacing: 0px;
        color: #222222;
      }

      p.stats{
        font-family: "Flame Sans";
        font-size: 17px;
        letter-spacing: 0px;
        color: #484848;
      }

    }

    div.rightDivs{
      grid-column: 7 / 9;
      grid-row: 1 / 2;
      justify-content: flex-end;
      margin-bottom: 1%;
      float: left;

      p.viewBy {
        float: left;
        font-family: 'Flame Sans';
        padding: 14px 23px 14px 7px;
        color: #484848;

      }

      button.viewButtons {
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        border-radius: 0px;
        padding: 12px 23px 12px 24px;

        &.restaurants {
          text-transform: capitalize;
        }

        span {
          font-size: 16px;
          font-family: 'Flame Sans';
        }
      }

      button.viewButtonsClicked {
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        border-radius: 0px;
        padding: 12px 23px 12px 24px;

        &.restaurants {
          text-transform: capitalize;
        }

        span {
          font-size: 16px;
          font-family: 'Flame Sans';
        }
      }


    }


  }
}

div.tableUtils {

  display: grid;
  grid-template-columns: 5.9% repeat(5, 1fr) 11% 9% 2% 3% 5.9%;
  grid-template-rows: 52px;
  margin-bottom: 30px;
  margin-top: 25px;

    .filter{
      background: #FFF4CC 0% 0% no-repeat padding-box !important;
      width: 116px;
      height: 60px    ;
      display: inline-block;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      margin-bottom: 25px;
    }

    .filtered {
      background: #FFFFFF 0% 0% no-repeat padding-box !important;
      border: 1px #CDCDCD solid;
      margin-left: 10px;




      &.filter-by-container {
        grid-column: 7 / 8;
        grid-row: 1 / 2;
      }

      &.sort-by-container {
        grid-column: 8 / 9;
        grid-row: 1 / 2;
        // display: grid;
        // grid-template-columns: 80% 20%;
        // grid-template-rows: 100%;

      }


      p {
        font-family: 'Flame Sans';
        font-size: 16px;
        color: #484848;
        float: left;
        padding: 10px 10px 10px 10px;
      }

      svg {
        float: left;
        margin: 10px 10px 10px 10px;
      }

      div.filterHeadings {
        margin: 0 0 0 0;
    }

    .filter:hover .filterHovered{
      cursor: pointer;
      display: block;

    }
    .filterIcon{
      width:18px;
      height:20px
    }

      .filteredHoveredCancel{

        position: relative;
        box-shadow: 4px 4px 24px -1px rgba(0, 0, 0, 0.3);
        background: #FFF4CC !important;
        margin-top:25px;
        right: 30px;
        padding: 10px;
        width: 200px;
        z-index: 1;
        display: none;

      }

    .filteredHovered:before{

      //content: '' '';
      //position: absolute;
      //bottom: 100%;
      //top: -30px;
      //width: 0;
      //height: 0;
      //border: 20px solid transparent;
      //border-bottom-color: #FFF4CC;
      //margin: auto;
      //left: 0;
      //right: 0;
      /* boxShadow: '4px 4px 24px -1px rgba(0, 0, 0, 0.3)', */
    }

      div.filteredHovered {
        position: relative;
        z-index: 1;
        background-color: #FFFFFF;
        box-shadow: 0px 3px 12px #00000014;
        margin-top: 12px;
        height:280px;
        overflow-y: auto;

        div.makeStyles-formControl-30 {
          margin: 0;

          button.filterButtons {
            border-bottom: 1px solid #ECECEC;
            border-radius: 0px;
          }
        }
      }

  }

  div.export-box-layout {
    grid-column: 10 / 11;
    grid-row: 1 / 2;
    margin: auto;

    div#export-div {

      button {

        span {
          font-family: 'Flame Sans';
          font-size: 16px;
          color: #484848;
          text-transform: capitalize;
        }
      }

      &.filteredHovered {
        position: absolute;
        z-index: 1;
        background-color: #FFFFFF;
        box-shadow: 0px 3px 12px #00000014;
        margin-top: 12px;
        width: 137px;
        right: 5.9%;

        p.MuiTypography-root {
          padding: 26px 26px 0px 26px;
          font-family: "Flame Sans";
          font-size: 20px;
          color: #35363A;

        }

        div.MuiFormGroup-root {
          padding: 26px 26px 26px 26px;

          label.MuiFormControlLabel-root {

            span {
              font-family: "Flame Sans";
              font-size: 20px;
              color: #35363A;
            }
          }
        }

      }

      &.filteredHoveredCancel {
        position: absolute;
        box-shadow: 4px 4px 24px -1px rgba(0, 0, 0, 0.3);
        background: #FFF4CC !important;
        margin-top:25px;
        right: 30px;
        padding: 10px;
        width: 200px;
        z-index: 1;
        display: none;
      }
    }
  }

}


.root-table-container {
  background-color: #fafafa !important;
  display: grid;
  grid-template-columns: 5.9% repeat(2,200px) repeat(5, 1fr) 5.9%;
  grid-template-rows: 50px 1080px;
  box-shadow: none !important;
}

.tabular-div-container-root {
  box-shadow: none;
  grid-column: 2 / 9;
  grid-row: 1 / 2;
  display: grid;

  .table-head-container {
    grid-column: 1 / 9;
    grid-row: 1 / 2;

    .table-row-header{
      display: grid;
      grid-template-columns: repeat(2, 200px) repeat(5, 1fr);
      grid-template-rows: 100%;

      .header {
        font-family: "FlameSans";
        font-size: 16px;
      }

      .header.restaurant, .header.restaurantBK {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
      }
    }
  }

  .table-body-container {
    grid-column: 1 / 9;
    grid-row: 2 / 3;

    &.closed {

    }

    &.open {
      background: #E68A4A14;
    }

    .tablerow {
      //grid-column: 2 / 3;
      //grid-row: 3 / 3;

      margin: 0px 0px 20px 0px;
      background: #FFFFFF;
      //border: 3px solid #E68A4A;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 6px;

      div.expansion-cell {
        margin: 50% 50% 0 0;
      }


      &.open {
        display: grid;
        grid-template-columns: repeat(2, 200px) repeat(4, 1fr) 11% 3.55%;
        grid-template-rows: 100%;
        margin: 0px 0px 0px 0px;
      }

      &.closed {
        display: grid;
        grid-template-columns: repeat(2, 200px) repeat(4, 1fr) 11% 3.55%;
        grid-template-rows: 100%;
      }

      .first-column-restaurant ,.first-column-restaurant-nested{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        display: flex;
        align-items: baseline;
        

        p.restaurantName {
          color: #222222;
          font-size: 25px;
          font-family: "FlameSans";
        }
        .serial-no{
          margin-right:20px;
          text-align: left;
font: normal normal normal 20px/24px Flame Sans;
letter-spacing: 0px;
color: #484848;

        }

      }
      .first-column-restaurant-nested{
        flex-direction: column;
      }

      p.currentValue {
        font-size: 31px;
        color:  #222222;
        font-family: 'FlameSans';
      }

      p.previousValue {
        font-size: 20px;
        color: #7D7F7D;
        font-family: 'Flame Sans';
      }
    }

    .tablerow-subtable {
      display: grid;
      grid-template-columns: repeat(2, 200px) repeat(4, 1fr) 11% 3.55%;
      grid-template-rows: 100%;
      border-left: 8px solid #E68A4A;
      .rest-link-div{
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        border-left: 8px solid #E68A4A;
      }

      .first-column-restaurant {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        margin: 10px 10px 10px 10px;

        p.restaurantName {
          color: #222222;
          font-size: 25px;
          font-family: "FlameSans";
        }

      }

      td {
        margin: 10px 10px 10px 10px;
      }

      p.currentValue {
        font-size: 31px;
        color:  #222222;
        font-family: 'FlameSans';
      }

      p.previousValue {
        font-size: 20px;
        color: #7D7F7D;
        font-family: 'Flame Sans';
      }


    }

  }



}
.sort-buttons{
  text-transform: capitalize !important;
}


