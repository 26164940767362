.footerBottomSplit {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-template-rows: 60px;
  background-color: #ff7d00;
  /* position: sticky;
  bottom: 0%; */
  /* height: 100%; */
}
.leftFooter {
    // grid-column: 1 / 3;
    grid-row: 1 / 2;
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100%;
    justify-content: end;
}
.trademark {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      margin: 5px 0 5px 0;
}
.pTrademark {
        font-size: 14px;
        color: #CDCDCD;
        font-family: 'Flame Sans';
        height: 22px;
        border-right: 1px solid #FFFFFF;
        text-align: center;
        padding-right: 10px;
      }


  .rightFooter {
    // grid-column: 3 / 5;
    grid-row: 1 / 2;
  }
    .textBottom{
      float:left;
      margin: 5px 0 5px 20px;
    }
      .textLi {
        font-family: 'Flame Sans';
        font-size: 14px;
        color: #FFFFFF;
        height: 22px;
      }

