div.grid-layout {
  display: grid;

  @media only screen and(min-width: 550px) {
    grid-template-columns: 80px repeat(3,1fr) 80px;
  }

  @media only screen and(max-width: 551px) {
    grid-template-columns: 5px repeat(3,1fr) 5px;
  }

  div.container-layout {
    grid-column: 2 / 5;
    grid-row: 1 / 2;
    display: grid;

    @media only screen and(min-width: 550px) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 60px;
    }

    @media only screen and(max-width: 551px) {
      grid-template-columns: 1fr;
      grid-column-gap: 5px;
      grid-row-gap: 10px;
    }

    div.header-breadcrumb {
      grid-column: 1 / 5;
      grid-row: 1 / 2;
      display: grid;

      @media only screen and (min-width: 550px) {
        grid-template-rows: 25% 30% 45%;
        grid-template-columns: repeat(6, 1fr);
      }
      @media only screen and(max-width: 551px) {
        grid-template-columns: repeat(1, 1fr);
      }

      div.header-left {
        display: grid;
        grid-template-rows: 12% 12% 76%;
        grid-template-columns: repeat(1, 1fr);

        @media only screen and (min-width: 550px) {
          grid-column: 1 / 3;
          grid-row: 1 / 4;
        }
        @media only screen and(max-width: 551px) {

        }

        div.breadcrumb-trail-route {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }

        div.back-link {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
        }

        div.user-name-title {
          grid-column: 1 / 2;
          grid-row: 3 / 4;

          p {
            font-family: 'FlameSans';
            font-size: 39px;
            margin: 0;
          }
        }
      }
    }

    div.header-right {
      display: grid;

      @media only screen and (min-width: 550px) {
        grid-template-columns: repeat(1, 1fr);
        grid-column: 3 / 5;
        grid-template-rows: 12% 12% 76%;
        grid-row: 1 / 4;
      }
      @media only screen and(max-width: 551px) {
        // grid-column: 3 / 5;
        // grid-row: 1 / 4;
      }


      div.restaurant-description {
        grid-column: 1 / 2;
        grid-row: 3 / 4;

        div.name-and-restaurant {

          div.gmname {
            float:left;
            padding: 0px 10px 0px 0px;
            border-right: 1px solid #CDCDCD;

          }

          div.restno {
            padding: 0px 0px 0px 10px;
          }

        }

        p {
          @media only screen and (min-width: 550px) {
            p {
              font-size: 25px;
              font-family: "Flame Sans";
              margin: 0;
            }
          }
          @media only screen and(max-width: 551px) {
            p {
              font-size: 16px;
              font-family: "Flame Sans";
              margin: 0;
            }
          }
        }
      }
    }

    div.d3-charts-container {

      display: grid;

      @media only screen and (min-width: 550px) {
        grid-template-columns: 33% 67%;
        grid-template-rows: 100%;
        grid-column: 1 / 5;
        grid-row: 2 / 3;
      }
      @media only screen and (max-width: 551px) {
        grid-template-rows: 45% 55%;
        grid-template-columns: 100%;
        grid-row-gap: 54px;
        grid-column: 1 / 5;
        grid-row: 2 / 3;
      }

      div.gauge-chart-container {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        display: grid;

        @media only screen and (min-width: 550px) {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 26% 11% 63%;
        }

        @media only screen and(max-width: 551px) {
          grid-template-columns: 28px 1fr 28px;
          grid-template-rows: 0% 28% 72%;

        }

          div.gauge-chart-header {
          grid-column: 1 / 4;
          grid-row: 1 / 2;

          p {
            font-family: 'Flame Sans';
            font-size: 25px;
            color: #222222;
            margin: 0;
          }

        }

        div.chart-two-thirds {

          @media only screen and (min-width: 550px) {
            grid-column: 1 / 3;
            grid-row: 2 / 4;
          }

          @media only screen and (max-width: 551px) {
            grid-column: 2 / 3;
            grid-row: 2 / 4;
          }

        }
      }

      div.star-trend-container {

        @media only screen and (min-width: 550px) {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }

        @media only screen and (max-width: 550px) {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
        }

      }
    }

    div.restaurant-cards-container {
      grid-column: 1 / 5;
      grid-row: 3 / 4;
      display: grid;

      @media only screen and (min-width: 550px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 5% 95%;
        grid-column-gap: 40px;
      }

      @media only screen and (max-width: 550px) {
        grid-template-columns: 20px 1fr 20px;
        grid-template-rows: 40% 35% 21%;

        p.alertMessage {
          font-size: 14px;
        }
      }

        div.restaurant-cards-header {
        grid-column: 1 / 5;
        grid-row: 1 / 2;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 100%;

        div.cards-layout {
          font-family: 'FlameSans-Bold';
          font-size: 31px;

          &.header-Guest {
            color: #C6371E;
          }

          &.header-Team {
            color: #408441;
          }

          &.header-Standards {
            color: #693F34;
          }
        }

      }

      div.card-stacked-layout {

        @media only screen and (min-width: 550px) {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(2, 1fr);
          grid-row-gap: 20px;
        }

        @media only screen and(max-width: 551px) {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          display: grid;
          grid-template-columns: 1fr;
          grid-row-gap: 20px;
          grid-template-rows: 56px repeat(2, 1fr);

          #guestsatisfactionacr {
            grid-column: 2 / 3;
            grid-row: 2 / 3;

          }

          #windowtimesos {
            grid-column: 2 / 3;
            grid-row: 3 / 4;

          }
        }

        div.card-table-cell {
          grid-column: 1 / 2;
        }
      }

      div.card-stacked-layout-second-col {

        @media only screen and (min-width: 550px) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(2, 1fr);
          grid-row-gap: 20px;

        }

        @media only screen and(max-width: 551px) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
          display: grid;
          grid-template-columns: 0% 1fr 0%;
          grid-template-rows: 56px repeat(2, 1fr);
          grid-row-gap: 20px;

          #avgtrainingrate {
            grid-column: 2 / 3;
            grid-row: 2 / 3;

          }

          #turnoverrate {
            grid-column: 2 / 3;
            grid-row: 3 / 4;

          }
        }



        div.card-table-cell {
          grid-column: 1 / 2;
        }
      }

      div.card-stacked-layout-third-col {

        @media only screen and (min-width: 550px) {
          grid-column: 3 / 4;
          grid-row: 2 / 3;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(2, 1fr);
          grid-row-gap: 20px;
        }

        @media only screen and(max-width: 551px) {
          grid-column: 2 / 3;
          grid-row: 3 / 4;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 56px 1fr;

          #brandstandards {
            grid-column: 2 / 3;
            grid-row: 2 / 3;

          }

        }



          div.card-table-cell {
          grid-column: 1 / 2;
        }
      }



    }
  }

  .innerText {
    color: white;
    font-family: 'Chicken SansBold';
    font-size: 34px;
    padding-left: 34px;
  }

  .clear-card {
    background-color: #FFFFFF;
    -webkit-filter: drop-shadow( 0px 3px 10px #0000001A);
    filter: drop-shadow( 0px 3px 10px #0000001A );
    border-radius: 10px;

    .card-title {
      color: #222222;
      font-family: 'Chicken Sans';
      font-size: 27px;
      padding: 20px 100px 0 100px;
    }

  }

  .tileDetails.GuestTile {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    width: auto;
    height: auto;
  }

  .tileDetails.TeamTile {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    width: auto;
    height: auto;
  }

  .tileDetails.StandardTile {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    width: auto;
    height: auto;
  }



}
