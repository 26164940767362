.cards-layout {
  grid-column: 2 / 7;
  grid-row: 1 / 3;
  display: grid;
  grid-template-rows: 50px 376px;
  grid-column-gap: 15px;

  div.cards-title {

    grid-column: 1 / 2;
    grid-row: 1 / 2;

    text.h2-card-header {
      font-size: 34px;
      font-family: 'Chicken SansBold';

      &.Guest {
        color: #00857E;
      }

      &.Team {
        color: #911987;
      }

      &.Standards {
        color: #C6371E;
      }

    }
  }
}