svg.svg-danger{
    path.a{
        fill:url("#warning-a");
    }
    path.b{
        opacity:0.05;
    }
    path.c{
        opacity:0.07;
    }
    path.d{
        fill:#fff;
    }

}