svg.svg-warning{
    path.a{
        
        fill:#ff7300;
    }

    path.b{
        opacity:0.05;
    }
    path.c{
        opacity:0.07;
    }
    path.d{
        fill:#fff;
    }
}