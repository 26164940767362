div.site-navigation-arrow {
  grid-row: 1 / 2;
  padding: 24px 0 0 0;
  font-family: "Flame Sans";
  font-size: 20px;
  color: #222222;

  &.two-cols-layout {
    grid-column: 1 / 3;
    margin-bottom: 10px;
  }

  &.three-cols-layout {
    grid-column: 1 / 7;
  }

}

div.site-navigation-back {
  grid-row: 2 / 3;

  &.two-cols-layout {
    grid-column: 1 / 3;
  }

  &.three-cols-layout {
    grid-column: 1 / 7;
  }

  a {
    font-family: "Flame Sans";
    font-size: 20px;
    color: #484848;
  }
}

div.site-navigation-time-period-text {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 100%;

  &.two-cols-layout {
    grid-column: 1 / 7;

    p {
      font-family: "Flame Sans";
      font-size: 16px;
      grid-column: 6 / 7;
      margin: 0;
    }
  }

  &.three-cols-layout {
    grid-column: 1 / 7;

    p {
      font-family: "Flame Sans";
      font-size: 16px;
      grid-column: 6 / 7;
      margin: 0;
    }
  }

}

div.site-navigation-header-dropdown {
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 100%;

  &.two-cols-layout {
    grid-column: 1 / 3;
  }

  &.three-cols-layout {
    grid-column: 1 / 7;
  }

  div.user-name-title {
    grid-row: 1 / 2;

    @media only screen and (min-width: 550px) {

      grid-column: 1 / 2;

      p {
        font-family: 'FlameSans';
        font-size: 39px;
        margin: 0;
      }
    }
    @media only screen and(max-width: 551px) {

      grid-column: 1 / 4;

      p {
        font-family: 'Flame Sans';
        font-size: 16px;
        margin: 0;
      }
    }



  }

    .dropdown-right-box {
      grid-column: 6 / 7;
      grid-row: 1 / 2;

      div.filtered {
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        border: 1px #CDCDCD solid;
        width: 100%;
        height: 52px;
        font-family: "Flame Sans";
        font-size: 16px;
        color: #484848;
        padding-left: 10px;

        .MuiSelect-select:focus {
          background: #FFFFFF;
        }

        &.MuiInput-underline:before {
          border-bottom: none;

        }

        &.MuiInput-underline:after {
          border-bottom: none;
        }

      }

      label.time-period-label-dropdown {
        font-family: "Flame Sans";
        margin: 0 0 5px 0;
        font-size: 16px;
        color: #484848;
      }
    }
}

li.dropdown-options-customized {
  border-bottom: 1px solid #F2F2F2;
  font-family: "Flame Sans";
  font-size: 16px;
  color: #484848;

  &.MuiListItem-root.Mui-selected {
    background: #E68A4A1A;
  }
}

.filtered{
  // border: 1px solid #CDCDCD;
  grid-column: 6/7;
  //width: 230px;
}
.filteredHeadings{
  
  display: flex;
  justify-content: space-between;
  border: 1px solid #CDCDCD;
  padding: 10px 6px;
  cursor: pointer;
}
.filter:hover .filterHovered{
  cursor: pointer;
  display: block;

}
.filterIcon{
  width:18px;
  height:20px
}

  .filteredHoveredCancel{

    position: relative;
    box-shadow: 4px 4px 24px -1px rgba(0, 0, 0, 0.3);
    background: #FFF4CC !important;
    margin-top:25px;
    right: 30px;
    padding: 10px;
    width: 200px;
    z-index: 1;
    display: none;

  }
  .filteredHovered{

    position: relative;
    box-shadow: 4px 4px 24px -1px rgba(0, 0, 0, 0.3);
    background: #FFFFFF !important;
    margin-top:5px;
   
    padding: 10px;
    //width: 230px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  