.axis path, .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
}
/* g>rect {
    fill: #408441;
} */
g>text{
    font: 20px Flame Sans;
}
.d3-component{
    height:550px;
}