.card-container {
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #0000001A;
  display: grid;
  grid-template-columns: 1fr;

  div.card-title-first-row {

    grid-column: 1 / 2;
    grid-row: 1 / 2;

    p.text-detail-head {
      font-size: 20px;
      font-family: 'FlameSans';
      padding: 5% 5% 0 5%;
      color: #222222;
      margin: 0;
    }

    p.text-weight-byline {
      font-size: 14px;
      color: #484848;
      font-family: "Flame Sans";
      margin: 0;
      padding: 0% 5% 0 5%;

    }

    div.star-layout-first-row {
      display: grid;

      &.columnar {
        grid-template-columns: 15% 60% 20%;
      }

      &.stacked {
        grid-template-columns: 12% 31% 57%;
      }

      p.p-star-rating {
        color: #484848;
        font-family: "Flame Sans";
        font-size: 17px;
        margin: 0;
        padding: 20% 0 0 30%;
      }

      div.div-star-rating {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        padding: 3% 0 0 1%;
      }

    }

  }

  div.card-second-row {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 65% 35%;

    div#guestsatisfactionacr-first{

      svg {
        rect#background-clip-guestsatisfactionacr-first {
          fill: #F9EBE8;
        }
        rect#circle-clip-guestsatisfactionacr {
          fill: #C6371E;
        }
      }

    }

    div#windowtimesos-first {

      svg {
        rect#background-clip-windowtimesos-first {
          fill: #F9EBE8;
        }
        rect#circle-clip-windowtimesos {
          fill: #C6371E;
        }
      }

    }

    div#avgtrainingrate-first {

      svg {
        rect#background-clip-avgtrainingrate-first {
          fill: #ECF2EC;
        }
        rect#circle-clip-avgtrainingrate {
          fill: #418441;
        }
      }
    }

    div#turnoverrate-first {

      svg {
        rect#background-clip-turnoverrate-first {
          fill: #ECF2EC;
        }
        rect#circle-clip-turnoverrate {
          fill: #418441;
        }
      }

    }

    div#brandstandards-first {

      svg {
        rect#background-clip-brandstandards-first {
          fill: #EEEAE8;
        }
        rect#circle-clip-brandstandards {
          fill: #673E32;
        }
      }

    }


    div.inner-bar-chart-line {
      grid-column: 1 / 2;
      grid-row: 1 / 2;

      svg {

        text {
          font-family: "Flame Sans";
          font-size: 14px;

          &.innerText-value {
            font-family: "FlameSans";
            fill: #FFFFFF;
            font-size: 17px;
          }

        }

        path.domain {
          stroke: none;
        }

        text.text-bottom {
          color: white;
        }

        text.text-hide {
          opacity: 0;
        }

      }


    }

    div.star-rating-row-series {
      grid-row: 2 / 3;

      text {
        font-family: "Flame Sans";
        font-size: 14px;
        fill: #484848
      }
    }

  }

  div.card-third-row {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50% 50%;

    div#guestsatisfactionacr-second {

      svg {
        rect#background-clip-guestsatisfactionacr-second {
          fill: #F9EBE8;
        }
        rect#circle-clip-guestsatisfactionacr {
          fill: #D05B46;
        }
      }

    }

    div#guestsatisfactionacr-third {

      svg {
        rect#background-clip-guestsatisfactionacr-third {
          fill: #F9EBE8;
        }
        rect#circle-clip-guestsatisfactionacr {
          fill: #D05B46;
        }
      }

    }

    div#windowtimesos-second {

      svg {
        rect#background-clip-windowtimesos-second {
          fill: #F9EBE8;
        }
        rect#circle-clip-windowtimesos {
          fill: #D05B46;
        }
      }

    }

    div#windowtimesos-third {

      svg {
        rect#background-clip-windowtimesos-third {
          fill: #F9EBE8;
        }
        rect#circle-clip-windowtimesos {
          fill: #D05B46;
        }
      }

    }

    div#avgtrainingrate-second {

      svg {
        rect#background-clip-avgtrainingrate-second {
          fill: #ECF2EC;
        }
        rect#circle-clip-avgtrainingrate {
          fill: #5E965F;
        }
      }

    }

    div#avgtrainingrate-third {

      svg {
        rect#background-clip-avgtrainingrate-third {
          fill: #ECF2EC;
        }
        rect#circle-clip-avgtrainingrate {
          fill: #5E965F;
        }
      }

    }

    div#turnoverrate-second {

      svg {
        rect#background-clip-turnoverrate-second {
          fill: #ECF2EC;
        }
        rect#circle-clip-turnoverrate {
          fill: #5E965F;
        }
      }

    }

    div#turnoverrate-third {

      svg {
        rect#background-clip-turnoverrate-third {
          fill: #ECF2EC;
        }
        rect#circle-clip-turnoverrate {
          fill: #5E965F;
        }
      }

    }

    div#brandstandards-second {

      svg {
        rect#background-clip-brandstandards-second {
          fill: #EEEAE8;
        }
        rect#circle-clip-brandstandards {
          fill: #836158;
        }
      }

    }

    div#brandstandards-third {

      svg {
        rect#background-clip-brandstandards-third {
          fill: #EEEAE8;
        }
        rect#circle-clip-brandstandards {
          fill: #836158;
        }
      }

    }

    svg {
      text {
        font-family: "Flame Sans";

        &.innerText-value {
          font-family: "FlameSans";
          fill: #FFFFFF;
          font-size: 17px;
        }

        &.barTitle {
          font-size: 16px;
          font-family: "FlameSans";
          fill: #484848;
        }

      }
    }
  }



}
