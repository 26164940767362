.rootReview{
    padding: 10px 114px;
    .breadcrumb{
        color:#502314;
        font: normal normal normal 20px/24px Flame Sans;
        margin-bottom: 20px;
        .bread-active{
            color: #484848;
        }
    }
    .MuiPagination-root	{
        margin-left:45%;
        margin-top: 15px;
        
letter-spacing: 0px;
.MuiPaginationItem-root{
    font: normal normal normal 27px Flame Sans;
}
.Mui-selected{
    color: #502314;
    background-color: transparent;
    
}

    }
    .rest-details{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .rating{
            display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
            .rating-title{
                text-align: left;
                font: normal normal normal 20px/37px Flame Sans;
                letter-spacing: 0px;
                color: #7D7F7D;
                margin:0px;
            }
            .rating-star{
                width:60px;
                height:60px
            }
            .tooltip{
                fill: #1589EE;
                position: relative;
                // bottom:20px;
                font-size: 35px;
                left:50px;
                top:10px;
                background-color: #FFFFFF;
            }
            .rating-stars{
                display: flex;
            }
        }
        .rest-details-head{
            display: flex;
        .rest-detail{
            margin-right: 30px;
            .rest-name{
                text-align: left;
                font: normal normal bold 39px Flame Sans;
                letter-spacing: 0px;
                color: #222222;
                margin: 0px;
            }
            .rest-address{
                text-align: left;
                font: normal normal normal 20px Flame Sans;
                letter-spacing: 0px;
                color: #484848;
                margin: 0px;
            }
        }
    
        .average-svg{
            margin-top: 30px;
        }
    }

        .timePeriodDropDown {
            background: none !important;
            padding: 15px;
            width: 250px;
            height: 80px;
            margin-right: 20px;
          }
          .formControl {
            
            min-width: 200px;
          }
          .selectEmpty {
            margin: 15px;
            min-width: 200px;
          }
    }
    .headers{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .headers-left{
            display: flex;
            align-items: center;
        .headers-title{
            text-align: left;
            font: normal normal bold 31px Flame Sans;
            letter-spacing: 0px;
            color: #222222;
        }
        .headers-stars-container{
            display: flex;
        .headers-stars{
            text-align: left;
            font: normal normal normal 20px Flame Sans;
            letter-spacing: 0px;
            color: #222222;
            margin-left: 10px;
            display: flex;
           align-items: center;
           .one{
            background: #D90F06 0% 0% no-repeat padding-box;
           }
           .three{
            background: #F27900 0% 0% no-repeat padding-box;
           }
           .five{
            background: #F3AD3D 0% 0% no-repeat padding-box;
           }
            .stars-color{

                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            
        }
    }
    }
    .headers-right{
        margin-right:3%;
        .search-input{
            min-width: 150px;
            .MuiOutlinedInput-input{
                width:100px
            }
        }
        .select-sort{
            width: 100px;
            margin-right: 40px;
        }
        .filtered{
   
            width: 120px;
            height: 56px;
            display: inline-block;
            justify-content: center;
           
            border: 1px solid #CDCDCD;
            border-radius: 3px;
            color: #484848 !important;
            margin-left: 10px;
            margin-bottom: 10px;
        }
        .filtered:hover{
            border: 1px solid black;
            cursor: pointer;
        }
        .filterHeadings{
            display: flex;
            margin-top: 15px;
            margin-left: 15px;
            justify-content: space-between;
        }
        
        .filteredHovered{
            
            position: relative;
            box-shadow: 4px 4px 24px -1px rgba(0, 0, 0, 0.3);
            background-color: #FFFFFF;
            margin-top:25px;
            right: 30px;
            padding: 10px;
            width: 150px;
            z-index: 1;
            display: block;
        
        }
        .filteredHoveredCancel{
            
            position: relative;
            box-shadow: 4px 4px 24px -1px rgba(0, 0, 0, 0.3);
            background-color: #FFFFFF;
            margin-top:25px;
            right: 30px;
            padding: 10px;
            width: 200px;
            z-index: 1;
            display: none;
        
        }
        .filterHovered::before{
            
            content: '' '';
            position: absolute;
            bottom: 100%;
            top: -30px;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-bottom-color: #FFF4CC;
        margin: auto;
        left: 0;
        right: 0;
             /* boxShadow: '4px 4px 24px -1px rgba(0, 0, 0, 0.3)', */
          }
    }
    }
    .reviews{
        display: grid;
        grid-template-columns: repeat(2,1fr);
       
        grid-column-gap: 20px;
        grid-row-gap:1%;
        margin-right: 40px;
        .angry{
            border-left:8px solid  #D90F06 ;
        }
        .success{
            border-left:8px solid #F3AD3D ;
        }
        .neutral{
            border-left:8px solid  #F27900  ;
        }

    .review-container{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        padding:0px 20px;
        margin:5px;

        .review-head{
            display: flex;
            align-items: center;
            .review-head-name{
                font: normal normal normal 20px/30px Flame Sans;
letter-spacing: 0px;
color: #222222;
margin: 0px 5px;
            }
            .review-star{
                width: 28px;
                height: 28px;
            }
        }
        .review-content{
            display: flex;
            justify-content: space-between;
            .review-desc{
                text-align: left;
                font: normal normal normal 16px Flame Sans;
                letter-spacing: 0px;
                color: #484848;
                margin-left: 10px;
            }
        }
    }
    }

}
.tooltip-content{
    background-color:white;
    color:white;
    
    
    border: "1px solid #000";
    .tooltip-content-head{

        font: normal normal bold 16px Flame Sans;
        color:black
    }
    .tooltip-content-body{
       
        font: normal normal normal 16px Flame Sans;
        color:black
    }
}
@media only screen  and (max-width:600px){
    .rootReview{
        padding:10px;
        .rest-details{
            .rest-details-head{
                flex-direction: column;
                .rest-detail{
                    .rest-name{
                        font: normal normal normal 16px Flame Sans;
                    }
                    .rest-address{
                        font: normal normal normal 16px Flame Sans;
                    }
                }
            }
            .timePeriodDropDown{
                display: none;
            }
            .rating{
                .rating-title{
                    font: normal normal normal 16px/19px Flame Sans;
                }
                .rating-star{
                    width:36px;
                    height:36px;
                }
            }
        }
        .headers{
            flex-direction: column;
            align-items: flex-start;
            .headers-left{
                .headers-title{
                    font: normal normal bold 20px/24px Flame;
                    align-self: flex-start;
                }
                flex-direction:column ;
                .headers-stars-container{
                    display:flex;
                }
            }
        }
    .reviews{
        grid-template-columns: repeat(1,1fr);
    }
    .MuiPagination-root{
        margin-left: 25%;
    }
}
}